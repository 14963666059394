import { bbk } from './items/bbk';
import { bellyAndBrain } from './items/belly-and-brain';
import { foxityFilms } from './items/foxity-films';
import { lemonScentedTea } from './items/lemon-scented-tea';
import { sunshineAndSausages } from './items/sunshine-and-sausages';
import { globalorange } from './items/globalorange';
import { level30Wizards } from './items/level-30-wizards';

export const partnersList = [
    bbk,
    bellyAndBrain,
    foxityFilms,
    lemonScentedTea,
    sunshineAndSausages,
    globalorange,
    level30Wizards,
];
