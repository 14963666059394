export const data = {
    seo: {
        title: 'Blog',
    },
    path: '/blog',
    hero: {
        title: 'Work hard. <br /> <span>Play</span> harder',
        text: [
            'Our passion for digital design doesn’t just include creating great products. We also love to inspire other agencies and companies with our knowledge and skills.',
        ],
    },
};
