import React from 'react';

export const BlogHeading = props => {
    const { writersName, postDate } = props;

    return (
        <aside>
            <p>
                By {writersName} <br />
                {postDate}
            </p>
        </aside>
    );
};
