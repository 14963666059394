import React from 'react';
import { motion, useReducedMotion } from 'framer-motion';

//Data
import { options } from '@data/en/options/options';
import { data as careersPageData } from '@data/en/pages/careers';
import { data as blogPageData } from '@data/en/pages/blog';
import { formatPhoneNumber } from '@lib/formatPhoneNumber';

//Styles
import styles from './Hero.module.scss';

//Components
import { AnchorButton } from '@components/Buttons/AnchorButton';
import { Container } from '@components/Utilities/Container/Container';
import { BlogHeading } from '@components/BlogHeading/BlogHeading';
import { WavyHeroTitle } from '@components/Animations/WavyHeroTitle/WavyHeroTitle';

//Images
import LinkedInIconBlack from '@images/icons/linkedin-black.svg';
import InstagramIconBlack from '@images/icons/instagram-black.svg';

export const Hero = props => {
    const {
        title,
        text,
        button,
        className,
        small,
        type,
        red,
        writersName,
        postDate,
        subTitle,
        officeTitle,
        contactTitle,
        workHours,
    } = props;

    const {
        street,
        zip_code,
        city,
        country,
        google_maps_link,
        socials: { instagram, linkedin },
    } = options;

    const shouldReduceMotion = useReducedMotion();

    const fadeInVariants = !shouldReduceMotion
        ? {
              hidden: { opacity: 0 },
              visible(delay = 0.5) {
                  return {
                      opacity: 1,
                      transition: {
                          delay: delay,
                          duration: 1,
                      },
                  };
              },
          }
        : {};

    return (
        <section
            className={`${styles.hero} ${className ? className : ''} ${red ? styles.heroRed : ''} ${
                small ? styles.heroSmall : ''
            } ${type === 'contact' ? styles.heroContact : ''}`}
        >
            <Container>
                {type === 'career' && (
                    <motion.div variants={fadeInVariants} initial="hidden" animate="visible">
                        <AnchorButton href={`${careersPageData.path}`} back white>
                            Back to all jobs
                        </AnchorButton>
                    </motion.div>
                )}

                {type === 'blog' && (
                    <motion.div variants={fadeInVariants} initial="hidden" animate="visible">
                        <AnchorButton href={`${blogPageData.path}`} back>
                            Back to all articles
                        </AnchorButton>
                    </motion.div>
                )}

                <WavyHeroTitle
                    className={`h1 ${
                        type === 'career' && workHours ? styles.hero__titleCareerSpacing : ''
                    } ${styles.hero__title}`}
                >
                    {title}
                </WavyHeroTitle>

                {type === 'career' && workHours ? (
                    <motion.div variants={fadeInVariants} initial="hidden" animate="visible">
                        <div className={styles.hero__workHours}>{workHours}</div>
                    </motion.div>
                ) : null}

                {text &&
                    text.map((p, index) => {
                        return (
                            <motion.p
                                initial="hidden"
                                animate="visible"
                                variants={fadeInVariants}
                                className={`${styles.hero__text} big`}
                                key={index}
                            >
                                {p}
                            </motion.p>
                        );
                    })}

                {type === 'career' && (
                    <motion.div variants={fadeInVariants} initial="hidden" animate="visible">
                        <AnchorButton href="#form" tertiary white>
                            Apply now
                        </AnchorButton>
                    </motion.div>
                )}

                {button && (
                    <motion.div variants={fadeInVariants} initial="hidden" animate="visible">
                        <AnchorButton {...button}>{button.text}</AnchorButton>
                    </motion.div>
                )}

                {type === 'contact' && (
                    <div className={styles.hero__contact}>
                        {subTitle && (
                            <motion.h2
                                initial="hidden"
                                animate="visible"
                                variants={fadeInVariants}
                                className="h3"
                                dangerouslySetInnerHTML={{ __html: subTitle }}
                            ></motion.h2>
                        )}

                        <motion.div
                            initial="hidden"
                            animate="visible"
                            variants={fadeInVariants}
                            custom={1}
                            className={styles.hero__address}
                        >
                            {officeTitle && <h3 className={'h4'}>{officeTitle}</h3>}

                            <address>
                                <p>
                                    <a
                                        className={styles.hero__contactLink}
                                        href={google_maps_link}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {street} <br />
                                        {zip_code} {city} <br />
                                        {country} <br />
                                    </a>
                                </p>
                            </address>
                        </motion.div>

                        <motion.div
                            initial="hidden"
                            animate="visible"
                            variants={fadeInVariants}
                            custom={1.5}
                            className={styles.hero__contactInfo}
                        >
                            {contactTitle && <h3 className={'h4'}>{contactTitle}</h3>}

                            <nav className={styles.hero__contactNav}>
                                <ul>
                                    <li>
                                        <p>
                                            <a
                                                className={styles.hero__contactLink}
                                                href={`mailto:${options.email}`}
                                            >
                                                {options.email}
                                            </a>
                                        </p>
                                    </li>

                                    <li>
                                        <p>
                                            <a
                                                className={styles.hero__contactLink}
                                                href={`tel:${formatPhoneNumber(
                                                    options.phone_number,
                                                    true,
                                                )}`}
                                            >
                                                {formatPhoneNumber(options.phone_number)}
                                            </a>
                                        </p>
                                    </li>
                                </ul>
                            </nav>

                            {instagram || linkedin ? (
                                <nav className={styles.hero__socials}>
                                    <ul>
                                        {instagram && (
                                            <li>
                                                <a
                                                    href={`https://instagram.com/${instagram}`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <InstagramIconBlack />
                                                </a>
                                            </li>
                                        )}

                                        {linkedin && (
                                            <li>
                                                <a href={linkedin} target="_blank" rel="noreferrer">
                                                    <LinkedInIconBlack />
                                                </a>
                                            </li>
                                        )}
                                    </ul>
                                </nav>
                            ) : null}
                        </motion.div>
                    </div>
                )}

                {writersName && postDate ? (
                    <motion.div variants={fadeInVariants} initial="hidden" animate="visible">
                        <BlogHeading writersName={writersName} postDate={postDate} />
                    </motion.div>
                ) : null}
            </Container>
        </section>
    );
};
