import React, { useState, useEffect } from 'react';
import '@styles/lib/slick.scss';
import Slider from 'react-slick';
import { arrShuffle } from '@lib/arrShuffle';

//Data
import settings from './settings';
import { partnersList } from '@data/en/partners/index';

//Styles
import styles from './FeaturedPartners.module.scss';

//Components
import { Container } from '@components/Utilities/Container/Container';
import { AnchorButton } from '@components/Buttons/AnchorButton';
import { Slide } from './Slide';
import { FadeInWhenVisible } from '@components/Animations/FadeInWhenVisible/FadeInWhenVisible';
import { Cursor } from '@components/Cursor/Cursor';

export const FeaturedPartners = props => {
    const { title, text = [], button, partners = partnersList } = props;
    const [cursorActive, setCursorActive] = useState(false);
    const [slides, setSlides] = useState(partners);

    useEffect(() => {
        setSlides(arrShuffle(partners));
    }, [partners]);

    return (
        <section className={styles.featuredPartners}>
            <Container className={styles.featuredPartners__container}>
                <div
                    className={`${styles.featuredPartners__column} ${styles.featuredPartners__info}`}
                >
                    {title && (
                        <FadeInWhenVisible>
                            <h2 className={'h2'}>{title}</h2>
                        </FadeInWhenVisible>
                    )}

                    {text.map((p, i) => {
                        return (
                            <FadeInWhenVisible threshold={0.1} key={i}>
                                <p className="big">{p}</p>
                            </FadeInWhenVisible>
                        );
                    })}

                    {button && (
                        <FadeInWhenVisible>
                            <AnchorButton primary red href={button.href}>
                                {button.text}
                            </AnchorButton>
                        </FadeInWhenVisible>
                    )}
                </div>

                <FadeInWhenVisible
                    threshold={0.75}
                    amount={100}
                    duration={1}
                    direction="top"
                    className={`${styles.featuredPartners__column} ${styles.featuredPartners__sliderContainer}`}
                >
                    <div
                        role="presentation"
                        onMouseEnter={() => setCursorActive(true)}
                        onMouseLeave={() => setCursorActive(false)}
                    >
                        <Slider {...settings}>
                            {slides.map((partner, index) => {
                                return (
                                    <Slide
                                        key={index}
                                        path={partner.logo.path}
                                        alt={partner.logo.alt}
                                    />
                                );
                            })}
                        </Slider>
                        <Cursor cursorActive={cursorActive} type="slider-red-vertical" />
                    </div>
                </FadeInWhenVisible>
            </Container>
        </section>
    );
};
