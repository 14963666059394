import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

//Styles
import styles from './FeaturedPartners.module.scss';

//Components
import Img from 'gatsby-image';

export const Slide = props => {
    const { path, alt = '' } = props;

    const data = useStaticQuery(graphql`
        query {
            images: allFile {
                edges {
                    node {
                        relativePath
                        name
                        childImageSharp {
                            fluid(maxWidth: 1200, quality: 100) {
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                        }
                        publicURL
                    }
                }
            }
        }
    `);

    const image = data.images.edges.find(n => {
        return n.node.relativePath.includes(path);
    });

    if (!image) return null;

    const extension = path.match(/[^\\]*\.(\w+)$/)[1];
    if (extension === 'svg' || extension === 'gif') {
        return (
            <div className={styles.featuredPartners__slideWrapper}>
                <img
                    loading="lazy"
                    className={styles.featuredPartners__slideImg}
                    src={image.node.publicURL}
                    alt={alt}
                />
            </div>
        );
    }

    return (
        <div className={styles.featuredPartners__slideWrapper}>
            <Img
                className={styles.featuredPartners__slideImg}
                fluid={image.node.childImageSharp.fluid}
                alt={alt}
            />
        </div>
    );
};
