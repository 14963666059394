import React, { useState, useRef } from 'react';
import '@styles/lib/slick.scss';
import Slider from 'react-slick';

//Data
import settings from './settings';

//Styles
import styles from './SuperPowers.module.scss';

//Components
import { Container } from '@components/Utilities/Container/Container';
import { FadeInWhenVisible } from '@components/Animations/FadeInWhenVisible/FadeInWhenVisible';

//Images
import HandPointerRight from '@images/icons/hand-pointer-right.svg';

export const SuperPowers = props => {
    const { title, powers = [], autoplay = false, autoplaySpeed = 5000 } = props;
    const refSlider = useRef();
    const [currSlideIndex, setCurrSlideIndex] = useState(0);

    const onPowerClick = index => {
        refSlider.current.slickGoTo(index);
        setCurrSlideIndex(index);
    };

    if (!powers.length) return null;

    return (
        <section className={styles.superPowers}>
            <Container>
                {title && (
                    <FadeInWhenVisible>
                        <h2 className={`h2`} dangerouslySetInnerHTML={{ __html: title }}></h2>
                    </FadeInWhenVisible>
                )}
            </Container>

            <Container className={styles.superPowers__container}>
                <FadeInWhenVisible
                    threshold={0.25}
                    direction="left"
                    className={styles.superPowers__nav}
                >
                    <ul className={styles.superPowers__navList}>
                        {powers.map((power, index) => {
                            const { title, objective } = power;

                            return (
                                <li
                                    className={`${styles.superPowers__navItem} ${
                                        currSlideIndex === index
                                            ? styles.superPowers__navItemActive
                                            : ''
                                    }`}
                                    key={index}
                                >
                                    <button
                                        onClick={() => onPowerClick(index)}
                                        type="button"
                                        className={styles.superPowers__name}
                                    >
                                        <HandPointerRight
                                            className={styles.superPowers__handIcon}
                                        />
                                        {title}
                                    </button>

                                    {objective && (
                                        <p className={styles.superPowers__objective}>
                                            <span className={styles.superPowers__objectiveTitle}>
                                                Objective
                                            </span>
                                            {objective}
                                        </p>
                                    )}
                                </li>
                            );
                        })}
                    </ul>
                </FadeInWhenVisible>

                <FadeInWhenVisible
                    threshold={0.25}
                    direction="right"
                    className={styles.superPowers__sliderWrapper}
                >
                    <Slider
                        ref={refSlider}
                        afterChange={index => {
                            setCurrSlideIndex(index);
                        }}
                        {...settings}
                        autoplay={autoplay}
                        autoplaySpeed={autoplaySpeed}
                    >
                        {powers.map((power, index) => {
                            const { title, text, objective } = power;

                            return (
                                <article key={index} className={styles.superPowers__power}>
                                    <h2 className={`${styles.superPowers__powerTitle} h3`}>
                                        {title}
                                    </h2>

                                    {objective && (
                                        <p className={styles.superPowers__objective}>
                                            <span className={styles.superPowers__objectiveTitle}>
                                                Objective
                                            </span>
                                            {objective}
                                        </p>
                                    )}

                                    {text.map((p, i) => {
                                        return (
                                            <p className="big" key={i}>
                                                {p}
                                            </p>
                                        );
                                    })}
                                </article>
                            );
                        })}
                    </Slider>
                </FadeInWhenVisible>
            </Container>
        </section>
    );
};
