import React, { useEffect } from 'react';
import styles from './CTA.module.scss';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation, useReducedMotion } from 'framer-motion';

//Components
import { Container } from '@components/Utilities/Container/Container';
import { FadeInWhenVisible } from '@components/Animations/FadeInWhenVisible/FadeInWhenVisible';

//Images
import ArrowRightUpWhite from '@images/icons/arrow-right-up-white.svg';
import ArrowRightUpRed from '@images/icons/arrow-right-up-red.svg';

export const CTA = props => {
    const { title = '', text, href, white, small } = props;
    const controls = useAnimation();
    const titleWords = title.split(' ');
    const { ref, inView } = useInView({ threshold: 1, triggerOnce: true });
    const shouldReduceMotion = useReducedMotion();

    useEffect(() => {
        if (inView) {
            controls.start('visible');
        }
    }, [controls, inView]);

    const wordVariants = !shouldReduceMotion
        ? {
              hidden: { y: '100%' },
              visible(delay) {
                  return {
                      y: 0,
                      transition: {
                          delay: delay * 0.1,
                          type: 'spring',
                          duration: 0.2,
                          bounce: 0.5,
                          damping: 10,
                          mass: 1,
                          stiffness: 100,
                      },
                  };
              },
          }
        : {};

    return (
        <section
            className={`${styles.cta} ${white ? styles.ctaWhite : ''} ${
                small ? styles.ctaSmall : ''
            }`}
        >
            <Container>
                <a href={href}>
                    {text && (
                        <FadeInWhenVisible>
                            <p className={styles.cta__text}>{text}</p>
                        </FadeInWhenVisible>
                    )}

                    {title && (
                        <h4 ref={ref} className={styles.cta__title}>
                            {titleWords.map((word, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <div
                                            style={{ display: 'inline-block', overflow: 'hidden' }}
                                        >
                                            <motion.div
                                                style={{
                                                    display: 'inline-block',
                                                    position: 'relative',
                                                    top: '-0.9rem',
                                                }}
                                                initial="hidden"
                                                animate={controls}
                                                variants={wordVariants}
                                                custom={index}
                                            >
                                                {word}
                                            </motion.div>
                                        </div>

                                        {index !== titleWords.length - 1 ? ' ' : ''}
                                    </React.Fragment>
                                );
                            })}
                        </h4>
                    )}

                    <FadeInWhenVisible direction="left">
                        {white ? (
                            <ArrowRightUpRed className={styles.cta__icon} />
                        ) : (
                            <ArrowRightUpWhite className={styles.cta__icon} />
                        )}
                    </FadeInWhenVisible>
                </a>
            </Container>
        </section>
    );
};
