const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    autoplay: true,
    autoplaySpeed: 3000,
    swipeToSlide: true,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                vertical: false,
                centerMode: true,
                verticalSwiping: false,
            },
        },
        {
            breakpoint: 575,
            settings: {
                vertical: false,
                centerMode: true,
                verticalSwiping: false,
                slidesToShow: 2,
            },
        },
    ],
};

export default settings;
